@media only screen and (min-width: $screen-l) {
    .account-navigation {
        padding: 4rem;
    }

    .account-container {
        section {
            padding: 0 5rem 0 7rem;
        }
    }

    /*----------------------------------
    #Foloseste puncte
    ----------------------------------*/
    section.extra-chance,
    section.vouchers {
        padding: 0;
    }
}