@media only screen and (min-width: $screen-l) {

    /* --- Anti-Spam --- */

    .anti-spam {
        margin-top: 0rem;
        width: 32.5%;

        .section-title {
            font-size: 7.5rem;
            line-height: 5.5rem;
        }
    }

    .sondaj-register {
        .anti-spam {
            margin-top: 0;
        }
    }
}