@media only screen and (min-width: $screen-l) {
    .monthly-quiz-title {
        font-size: 15.5rem;
        position: absolute;
        left: 50%;
        top: 15rem;
        transform: translateX(-25%);
        width: auto;
    }

    .fb-like {
        width: 100%;
    }

    .monthly-quiz {
        font-size: 1.3rem;
        margin-left: 3rem;
        margin-top: 11.2rem;
        width: 75%;
    }

    .quiz-sponsor {
        font-size: 7.5rem;
    }

    .client-information-title {
        margin-top: 0;
    }

    .client-image {
        margin-top: 3rem;
        width: 100%;

        img {
            width: 100%;
        }
    }
}