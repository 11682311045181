@media only screen and (min-width: $screen-l) {
    .thankyou {
        padding: 0 5rem;

        .container-box {
            width: 32%;

            &--title {
                p {
                    &:last-child {
                        margin-top: -2rem;

                        span {
                            padding-left: 1.5rem;
                            letter-spacing: -0.25rem;
                        }
                    }
                }
            }
        }
    }
}