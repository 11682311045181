@media only screen and (min-width: $screen-l) {
    .footer {
        margin-top: 8.5rem;
    }

    .copyright {
        margin-bottom: 5rem;

        &-additional-info {
            //max-width: 25%;
        }
    }


}