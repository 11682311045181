/*----------------------------------
    #Mountly Quiz - Steps
----------------------------------*/

@media only screen and (min-width: $screen-l) {
    .quiz-container {
        .anti-spam {
            margin-top: 0;
        }
    }

    .quiz-form-container {
        box-sizing: border-box;
        padding: 0 5rem;
        width: 66%;
    }

    .ColumnFull {
        padding: 5rem;
        width: 100%;
        max-width: 130rem;
    }
}