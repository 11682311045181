@media only screen and (min-width: $screen-l) {

    /* --- Text --- */

    .title__container {
        //margin: 0 auto;
    }

    .title {
        margin: 0 auto;
        //max-width: 130rem;
        //width: 90%;
    }

    .ivox-info__title {
        margin: 0 auto;
        max-width: 128rem;
        width: 90%;
    }
}