@media only screen and (min-width: $screen-l) {
    .title.homepage-title {
        margin-left: 11.5%;
    }

    .homepage-container-top {
        -webkit-box-align: flex-start;
        -moz-box-align: flex-start;
        -ms-flex-align: flex-start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
    }

    .active-quiz {
        margin-top: -1rem;
        width: 52%;

        &-container {
            margin-left: -8.5rem;
            margin-top: -2.5rem;
            width: 68%;
        }

        &__description {
            font-size: 1.3rem;
        }

        &__footer {
            font-size: 3.9rem;
            letter-spacing: -0.2rem;
        }

        &__button {
            font-size: 1.4rem;
            padding-top: 1.8rem;
            padding-bottom: 1.8rem;
        }
    }

    .daily-question {
        box-sizing: border-box;
        margin-top: -8.5rem;
        width: 32%;

        &__title {
            margin: 0;
            font-size: 7.5rem;
        }

        &__subtitle {
            margin: -2rem 0 0;
            font-size: 6rem;
        }

        &__content {
            font-size: 3rem;
            font-weight: 900;
            min-height: 20rem;
            padding: 2rem 0 5rem;
        }

        &__link {
            font-size: 1.3rem;
            font-weight: 700;
        }
    }

    .homepage-container-bottom {
        -webkit-box-align: flex-start;
        -moz-box-align: flex-start;
        -ms-flex-align: flex-start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
    }

    .past-quiz {
        -webkit-align-self: stretch;
        align-self: stretch;
        width: 32.3%;
    }

    .business {
        //align-self: stretch;
        margin: 0;
        padding: 5rem;
        width: 32.3%;

        &__title {
            font-size: 6.1rem;
            line-height: 5rem;
        }

        &__image {
            margin: 0 0 4rem 0;
        }

       &__more-info-link {
           margin: 0 0;
           bottom: 5rem;
       }
    }
}